@mixin font($font-family, $font-file) {
    @font-face {
        font-family: $font-family;
        src: local('Rajdhani'), url($font-file) format('truetype');
        font-weight: 700;
        font-style: bold; } }


@include font('Morganite', './fonts/Morganite-Black.ttf');

.f-m {
	font-family: Morganite; }
